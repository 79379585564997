type Props = {
    type?: "submit" | "reset" | "button";
    className?: string;
    processing?: boolean;
    children: React.ReactNode;
    onClick?: () => void;
};

export default function PrimaryButton({
    type = "submit",
    className = "",
    processing,
    children,
    onClick,
}: Props) {
    return (
        <button
            type={type}
            onClick={onClick}
            className={
                `inline-flex items-center px-4 py-2 bg-gray-600 border border-transparent rounded-md font-semibold text-xs text-white uppercase tracking-widest hover:bg-gray-700 focus:bg-gray-700 active:bg-gray-900 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 transition ease-in-out duration-150 ${
                    processing && "opacity-25"
                } ` + className
            }
            disabled={processing}
        >
            {children}
        </button>
    );
}
